<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('partner_voucher_export')">
      <b-col sm>
        <div>
          <button class="btn btn-success m-2" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <!-- Search -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Code, Phone Number, Partner Invoice Number"
          >
              <b-input-group
              >
                <b-form-input
                  id="search"
                  type="search"
                  v-model="filterText"
                  placeholder="Name, Code, Phone Number, Partner Invoice Number ..."
                  @keyup.enter="doFilter"
                >
                </b-form-input>
                <b-input-group-append
                >
                  <b-button
                    variant="secondary" @click="resetFilter"
                    type="button"
                  >
                    Reset
                  </b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <!-- Prefix -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Date Type"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="filterDateType"
              :options="optionsDate"
              @change="doFilterSelect('dateType', filterDateType)">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <!-- Prefix -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Prefix"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="filterPrefix"
              :options="optionsPrefix"
              @change="doFilterSelect('prefix', filterPrefix)">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <!-- Date -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Range Date"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                :type="'voucher'"
                :disable="!filterDateType"
                @picker="doDateFilter">
              </range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <!-- Delivery -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Delivery Status"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="filterDelivery" :options="
            [
              { value: '', text: 'Choose Delivery Status' },
              { value: '1', text: 'Success' },
              { value: '2', text: 'Failed' }
            ]" @change="doFilterSelect('delivery_status', filterDelivery)"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <!-- Availability -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Availability"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="filterAvailability" :options="
            [
              { value: '', text: 'Choose Availability' },
              { value: '1', text: 'Available' },
              { value: '0', text: 'Expired' }
            ]" @change="doFilterSelect('available', filterAvailability)"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <!-- Redeem -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Redeem Status"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="filterRedeem" :options="
            [
              { value: '', text: 'Choose Redeem Status' },
              { value: '1', text: 'Redeemed' }
            ]" @change="doFilterSelect('redeem_status', filterRedeem)"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <!-- Partner -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Partner"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="filterPartner"
              :options="optionsPartner"
              @change="doFilterSelect('partner_id', filterPartner)">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      :api-mode="false"
			:fields="fields"
      :per-page="limit"
			:data-manager="dataManager">
      <template slot="created_at-slot" slot-scope="prop">
        <span>{{ prop.rowData.created_time || '-' }}</span>
      </template>
      <template slot="partner_invoice_number-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner_invoice_number || '-' }}</span>
      </template>
      <template slot="code-slot" slot-scope="prop">
        <span>{{ prop.rowData.code }}</span>
      </template>
      <template slot="prefix-slot" slot-scope="prop">
        <span>{{ prop.rowData.prefix || '-' }}</span>
      </template>
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }}</span>
      </template>
      <template slot="partner-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner_name || '-' }}</span>
      </template>
      <template slot="amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.amount }}</span>
      </template>
      <template slot="program_start-slot" slot-scope="prop">
        <span>{{ prop.rowData.start_time || '-' }}</span>
      </template>
      <template slot="program_end-slot" slot-scope="prop">
        <span>{{ prop.rowData.expired_time || '-' }}</span>
      </template>
      <template slot="phone-slot" slot-scope="prop">
        <span>{{ prop.rowData.phone_number || '-' }}</span>
      </template>
      <template slot="delivery_time-slot" slot-scope="prop">
        <span>{{ prop.rowData.delivery_time || '-' }}</span>
      </template>
      <template slot="redeem_status-slot" slot-scope="prop">
        <span>{{ prop.rowData.redeem_status }}</span>
      </template>
      <template slot="redeem_time-slot" slot-scope="prop">
        <span>{{ prop.rowData.redeem_time }}</span>
      </template>
      <template slot="failed-reason-slot" slot-scope="prop">
        <span style="color:red">{{ prop.rowData.failed_reason || '-' }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button v-if="$can('partner_voucher_detail')" class="btn btn-info" @click="onAction('detail-item', prop.rowData)" >Detail</button>&nbsp;
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="doMovePage('first')">
            <i v-if="currentPage === 1" class="fa fa-angle-double-left disabled"></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="doMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import axios from 'axios'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import moment from 'moment'

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable)

  export default {
    components: {
      Vuetable
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        page: 1,
        currentPage: 1,
        limit: 10,
        data: [],
        isLoading: false,
        isLoadingTable: false,
        startDate: '',
        endDate: '',
        filterText: '',
        filterPrefix: '',
        filterDelivery: '',
        filterAvailability: '',
        filterRedeem: '',
        filterPartner: '',
        filterDateType: '',
        optionsPartner: [
          { value: '', text: 'Choose Partner' }
        ],
        optionsPrefix: [
          { value: '', text: 'Choose Prefix' }
        ],
        optionsDate: [
          { value: '', text: 'Choose Date Type' },
          { value: '1', text: 'Start Date' },
          { value: '2', text: 'Expired Date' },
          { value: '3', text: 'Created Date' }
        ],
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `voucher/partner/list`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sort: 'created_time|desc',
        moreParams: {},
        fields: [
          {
            name: 'created_at-slot',
            sortField: 'created_time',
            title: 'Created Date'
          },
          {
            name: 'partner_invoice_number-slot',
            sortField: 'partner_invoice_number',
            title: 'Partner Invoice Number'
          },
          {
            name: 'code-slot',
            sortField: 'code',
            title: 'Code'
          },
          {
            name: 'prefix-slot',
            sortField: 'prefix',
            title: 'Prefix'
          },
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Name'
          },
          {
            name: 'partner-slot',
            sortField: 'partner_name',
            title: 'Partner'
          },
          {
            name: 'amount-slot',
            sortField: 'amount',
            title: 'Amount',
          },
          {
            name: 'program_start-slot',
            sortField: 'start_time',
            title: 'Start Time'
          },
          {
            name: 'program_end-slot',
            sortField: 'expired_time',
            title: 'Expired Time'
          },
          {
            name: 'phone-slot',
            sortField: 'phone_number',
            title: 'Latest Phone Number'
          },
          {
            name: 'delivery_status',
            sortField: 'delivery_status',
            title: 'Delivery Status',
            formatter: this.deliveryStatusFormater
          },
          {
            name: 'delivery_time-slot',
            sortField: 'delivery_time',
            title: 'Delivery Time'
          },
          {
            name: 'failed-reason-slot',
            sortField: 'failed-reason',
            title: 'Failed Reason',
          },
          {
            name: 'availability',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: 'Available',
            formatter: this.activeLabel
          },
          {
            name: 'redeem_status-slot',
            sortField: 'redeem_status',
            title: 'Redeem Status',
            formatter: this.redeemStatusFormater
          },
          {
            name: 'redeem_time-slot',
            sortField: 'redeem_time',
            title: 'Redeem Time',
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
      }
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      this.fetchs()
      this.fetchPrefix()
      this.fetchPartners()
    },
    methods: {
      fetchs() {
        const newParams = this.stringifyParams(this.moreParams)

        this.$http.get(`voucher/partner/list?sort=${encodeURIComponent(this.sort)}&page=${this.page}&per_page=10${newParams}`)
          .then((response) => {
            const startIndex = (response.data.current_page - 1) * this.limit;
            const endIndex = startIndex + this.limit;

            this.data = response.data.data;
            this.currentPage = response.data.current_page;
            this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';

            if (this.isLoadingTable) this.isLoadingTable = false;
          })
          .catch((err) => {
            this.handleLoadError(err)
          })
      },
      fetchPartners() {
        this.$http.get(`partner-list`)
          .then((result) => {
            this.isLoading = false
            const newPartner = result.data.map((item) => { return { value: item.id, text: item.name } })
            this.isLoading = false
            this.optionsPartner = [
              ...this.optionsPartner,
              ...newPartner
            ]
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      fetchPrefix() {
        this.$http.get(`voucher/partner/prefix`)
          .then((result) => {
            this.isLoading = false
            const newPrefix = result.data.data.map((item) => { return { value: item.prefix, text: item.prefix } })
            this.isLoading = false
            this.optionsPrefix = [
              ...this.optionsPrefix,
              ...newPrefix
            ]
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      redeemStatusFormater(value) {
        if (value) {
          return '<span>Reedemed</span>'
        } else {
          return '<span>-</span>'
        }
      },
      deliveryStatusFormater(value) {
        if (value === 'Success') {
          return '<span class="text-success">Success</span>'
        } else if (value === 'Failed') {
          return '<span class="text-danger">Failed</span>'
        } else {
          return '<span>-</span>'
        }
      },
      activeLabel(value) {
        if (value == true) {
          return '<span class="ui green label"><i class="fa fa-check"></i></span>'
        }else{
          return '<span class="ui red label"><i class="fa fa-times"></i></span>'
        }
      },
      async exportTable() {
        const newParams = await this.stringifyParams(this.moreParams)
        const currentDate = moment(this.$setDate);
        this.isLoading = true

        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = currentDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`voucher/partner/export?start_date=${startDate}&end_date=${endDate}${newParams}`)
          .then((result) => {
            this.isLoading = false
            const  exportPath = result.data.meta.data;
            window.location.href = exportPath;
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      onAction(action, data) {
        if (action == 'detail-item') {
          this.$router.push({name: 'Detail Voucher Partner', params: { id: btoa(data.id)}})
        }
      },
      doFilter() {
        this.$events.$emit('filter-set', this.filterText)
      },
      onFilterSet(filterText) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        this.moreParams = {
          ...newParams,
          'filter': encodeURIComponent(filterText)
        }
        this.fetchs()
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_date') && (key != 'end_date')))
        this.start_from = this.startDate
        this.end_to = this.endDate
        this.page = 1;
        this.moreParams = {
          ...newParams,
          'start_date': this.start_from,
          'end_date': this.end_to
        }
        this.fetchs()
      },
      doFilterSelect(type, value) {
        this.$events.$emit('select-set', type, value)
      },
      onFilterSelectSet(type, value) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => {
          if (type === 'dateType') {
            return key !== type && key !== 'start_date' && key != 'end_date'
          } else {
            return key !== type
          }
        }))

        this.moreParams = value ? {
          ...newParams,
          [type]: value
        } : newParams;

        if (type === 'dateType') {
          if (value) {
            const currentDate = moment(this.$setDate);
            const payload = {
              endDate: this.$setDate.format('YYYY-MM-DD'),
              startDate: currentDate.subtract(6, 'days').format('YYYY-MM-DD')
            }
            this.doDateFilter(payload)
          } else {
            this.resetDateFilter()
          }
        } else {
          this.fetchs()
        }
      },
      resetFilter() {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      resetDateFilter() {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      onFilterReset() {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        this.page = 1
        this.fetchs()
      },
      onDateFilterReset() {
        this.filterDateType = ''
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_date') && (key != 'end_date') && (key != 'dateType')))
        this.page = 1
        this.fetchs()
      },
      stringifyParams(params) {
        const filteredKeys = Object.keys(params).filter((key) => {
          const value = params[key]
          return value !== '' && value !== null && value !== undefined
        })

        if (filteredKeys.length === 0) {
          return ''
        } else if (filteredKeys.length === 1) {
          const key = filteredKeys[0];
          return `&${key}=${params[key]}`
        } else {
          return `&${filteredKeys.map((key) => `${key}=${params[key]}`).join('&')}`
        }
      },
      doMovePage(value) {
        this.$events.$emit('movePage', value)
      },
      onMovePage (eventData) {
        if (eventData === 'first') {
          this.page = 1;
          this.fetchs();
        } else if (eventData === 'prev') {
          this.page--;
          this.fetchs();
        } else {
          this.page++;
          this.fetchs();
        }
      },
      dataManager(sortOrder, pagination) {
        let local = this.data;

        if (sortOrder.length > 0) {
          const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

          if (this.sort !== newSort) {
            this.sort = newSort;
            this.fetchs();
          }
        }

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.limit
        );

        let from = pagination.from - 1;
        let to = from + this.limit;

        return {
          pagination: pagination,
          data: local.length > 0 ? _.slice(local, from, to) : []
        };
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('select-set', (type, eventData) => this.onFilterSelectSet(type, eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
      this.$events.$on('movePage', eventData => this.onMovePage(eventData))
    },
  }
</script>
<style>

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
